<template>
  <div ref="otherPaymentsMain">
    <b-container class="m-o w-100" fluid>
      <header-slot :chargeBack="true">
        <template #actions>
          <div
            class="d-flex justify-content-end align-items-center"
            style="gap: 10px"
            v-if="isTabCash && isManagement && isCeo"
          >
            <cash-amount-details />
          </div>
        </template>
      </header-slot>
      <b-nav card-header pills class="m-0">
        <b-nav-item
          v-if="!isCorrespondence"
          :to="{ name: $route.matched[1].meta.routeDepositZelle }"
          exact
          exact-active-class="active"
          :link-classes="['px-3', bgTabsNavs]"
          :active="isTabDepositZelle"
        >
          DEPOSIT / ZELLE
          <span v-if="counters.zelle > 0" class="ml-1">
            <feather-icon
              icon
              :badge="counters.zelle > 99 ? '99+' : counters.zelle"
              badge-classes="badge-danger"
            />
          </span>
        </b-nav-item>
        <b-nav-item
          :to="{ name: $route.matched[1].meta.routeCash }"
          exact
          exact-active-class="active"
          :link-classes="['px-3', bgTabsNavs]"
          :active="isTabCash"
        >
          CASH
          <span v-if="counters.cash > 0" class="ml-1">
            <feather-icon
              icon
              :badge="counters.cash > 99 ? '99+' : counters.cash"
              badge-classes="badge-danger"
            />
          </span>
        </b-nav-item>
        <b-nav-item
          :to="{ name: $route.matched[1].meta.routeMoneyOrder }"
          exact
          exact-active-class="active"
          :link-classes="['px-3', bgTabsNavs]"
          :active="isTabMoneyOrder"
        >
          MONEY ORDER / CASHIER CHECK / CHECK
          <span v-if="counters.checks > 0" class="ml-1">
            <feather-icon
              icon
              :badge="counters.checks > 99 ? '99+' : counters.checks"
              badge-classes="badge-danger"
            />
          </span>
        </b-nav-item>
      </b-nav>
      <b-card
        no-body
        class="border-top-primary border-3 border-table-radius px-0"
      >
        <router-view :key="key" />
      </b-card>
    </b-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import CashAmountDetails from "@/views/commons/components/other-payments/view/component/CashAmountDetails.vue"
export default {
  components: {
    CashAmountDetails,
  },
  data() {
    return {
      key: 0,
    }
  },
  computed: {
    ...mapGetters({
      counters: "other-payment-store/G_COUNTER_OP",
    }),
    isTabDepositZelle() {
      return this.$route.matched[2].meta.status == 1
    },
    isTabCash() {
      return this.$route.matched[2].meta.status == 2
    },
    isTabMoneyOrder() {
      return this.$route.matched[2].meta.status == 3
    },
    isCorrespondence() {
      return this.$route.matched[2].meta.module == 9
    },
    isManagement() {
      return this.$route.matched[2].meta.module == 16
    },
    moduleId() {
      return this.$route.matched[0].meta.module
    },
  },
  async created() {
    await this.A_COUNTER_OP({ module_id: this.moduleId })
  },
  methods: {
    ...mapActions({
      A_COUNTER_OP: "other-payment-store/A_COUNTER_OP",
    }),
  },
  watch: {
    "$route.name"(newVal) {
      this.key = newVal
    },
  },
}
</script>
