<template>
  <div class="d-flex justify-content-end cursor-pointer">
    <div
      class="av-balance-border-success float-right d-flex"
      :class="{ 'av-balance-border-grey': amountPending === 0 }"
      @click="openAmountDetail(0)"
    >
      <div
        class="av-balance-text-success text-center"
        :class="{ 'av-balance-text-grey': amountPending === 0 }"
      >
        Total Cash Pending
      </div>
      <h2
        class="av-balance-content-success"
        :class="{ 'av-balance-content-grey': amountPending === 0 }"
      >
        $
        {{ `${amountPending > 999 ? amountConvertToK + "K" : amountPending} ` }}
      </h2>
    </div>
    <div
      class="av-balance-border-grey float-right d-flex ml-1"
      @click="openAmountDetail(1)"
    >
      <div class="av-balance-text-grey text-center">Cash Collected</div>
      <h2 class="av-balance-content-grey">
        $ {{ `${amountPayed > 999 ? amountConvertToK + "K" : amountPayed} ` }}
      </h2>
    </div>
    <b-modal
      v-model="showAmountDetail"
      :title="typeModal ? 'Cash Collected' : 'Cash Pending'"
      title-tag="h3"
      modal-class="modal-primary"
      @hidden="hideModal(false)"
      size="xmd"
      scrollable
      hide-footer
    >
      <b-table
        id="tracking-list"
        ref="trackingList"
        no-border-collapse
        class="position-relative"
        :fields="visibleFields"
        show-empty
        no-provider-filtering
        sticky-header="50vh"
        primary-key="id"
        responsive="sm"
        :items="cashData"
        :busy="isBusy"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading ...</strong>
          </div>
        </template>

        <template #head(selected)>
          <b-form-checkbox v-model="selectAll" @input="selectedAll" />
        </template>

        <template v-slot:cell(selected)="row">
          <b-form-group>
            <b-form-checkbox
              v-model="row.item.selected"
              @input="selectedRow(row.item)"
            />
          </b-form-group>
        </template>

        <template #cell(client_name)="{ item }">
          <span class="text-primary cursor-pointer">
            {{ item.client_name || item.lead_name }}
          </span>
        </template>

        <template #cell(module)="{ item }">
          {{ item.module_name }}
        </template>

        <template #cell(status)="{ item }">
          <b-badge variant="light-success">
            {{ item.status }}
          </b-badge>
        </template>

        <template #cell(amount)="{ item }">
          <span class="text-success"> $ {{ item.amount | currency }} </span>
        </template>

        <template #cell(created_by)="{ item }">
          <span>{{ item.created_by }}</span>
          <br />
          <span>{{ item.created_at | myGlobalDay }}</span>
        </template>
        <!-- add row -->
        <template slot="bottom-row" v-if="amountPending > 0 && typeModal === 0">
          <td colspan="4"></td>
          <td
            colspan="1"
            class="text-center h5 text-success font-weight-bolder"
          >
            $ {{ amountSelected | currency }}
          </td>
          <td colspan="1">
            <b-button
              variant="success"
              class="float-right"
              @click="paySelected"
              :disabled="amountSelected === 0"
            >
              Charge Amount
            </b-button>
          </td>
        </template>
      </b-table>
    </b-modal>
  </div>
</template>

<script>
import OtherPaymentsService from "@/views/commons/components/other-payments/service/other-payment.service";
export default {
  data() {
    return {
      showAmountDetail: false,
      isBusy: false,
      cashData: [],
      fields: [
        {
          key: "selected",
          label: "Select",
          visible: true,
        },
        {
          key: "client_name",
          label: "Client Name",
          visible: true,
        },
        {
          key: "module",
          label: "Module",
          visible: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "status",
          label: "Status",
          visible: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "amount",
          label: "Amount",
          visible: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "created_by",
          label: "Created By At",
          visible: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
      ],
      selectAll: false,
      selected: [],
      amountPayed: 0,
      amountPending: 0,
      typeModal: 0,
    };
  },
  created() {
    this.getCashAmount();
  },
  computed: {
    amountSelected() {
      return this.selected.reduce((acc, item) => {
        return acc + item.amount * 1;
      }, 0);
    },
    amountPendingToK() {
      return this.amountPending / 1000;
    },
    amountConvertToK() {
      return this.amountPayed / 1000;
    },
    visibleFields() {
      return this.fields.filter((field) => field.visible);
    },
  },
  methods: {
    hideModal(value) {
      this.showAmountDetail = value;
      this.selected = [];
      this.selectAll = false;
    },
    openAmountDetail(type) {
      this.showAmountDetail = true;
      this.typeModal = type;
      if (type === 1) {
        this.fields[0].visible = false;
      } else {
        this.fields[0].visible = true;
      }
      this.getCashAmount(type);
    },
    async getCashAmount(type = 0) {
      this.addPreloader();
      try {
        this.cashData = [];
        const { data } = await OtherPaymentsService.getCashAmount({ type });
        this.cashData = data.payments;
        this.amountPayed = data.amount_payed;
        this.amountPending = data.amount_pending;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    selectedRow(data) {
      const index = this.selected.findIndex((select) => select.id === data.id);
      if (data.selected === true && index === -1) {
        this.selected.push(data);
      } else if (data.selected === false && index !== -1) {
        this.selected.splice(index, 1);
      }
    },
    selectedAll() {
      if (this.selectAll) {
        this.cashData.forEach((item) => (item.selected = true));
      } else {
        this.cashData.forEach((item) => (item.selected = false));
      }
    },
    async paySelected() {
      const { isConfirmed } = await this.showConfirmSwal(
        "Are you sure you want to pay this amount?"
      );
      if (!isConfirmed) return;
      this.addPreloader();
      try {
        const IdsSelected = this.selected.map((item) => item.id);
        await OtherPaymentsService.payCashSelected({
          ids: IdsSelected,
        });
        this.getCashAmount();
        this.hideModal(false);
        this.showSuccessSwal();
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
  },
};
</script>

<style>
.av-balance-border-success {
  border-radius: 7px;
  width: auto;
  padding: 3px;
  border: 1px solid #096b3c;
}
.av-balance-border-grey {
  border-radius: 7px;
  width: auto;
  padding: 3px;
  border: 1px solid #434745;
}
.av-balance-text-success {
  color: white;
  background-color: #096b3c;
  width: 100px;
  line-height: 13px;
  padding: 7px;
  margin: 0;
  font-size: 15px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 7px;
}
.av-balance-text-grey {
  color: white;
  width: 100px;
  line-height: 13px;
  padding: 7px;
  margin: 0;
  font-size: 15px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 7px;
  background-color: #434745 !important;
}
.av-balance-content-success {
  color: white;
  background-color: #00b25c;
  padding: 7px;
  margin: 0;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}
.av-balance-content-grey {
  color: white;
  padding: 7px;
  margin: 0;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  background-color: #7f8180 !important;
}
</style>
